

var ScrollTo = (function () {
  'use strict';

  // must have this class to work
    const scrollToTriggers = document.getElementsByClassName('scroll-to');
  

  let ticking = false;

  const scrollToContent = () => {
    for (let i = 0; i < scrollToTriggers.length; i++) {
      scrollToTriggers[i].addEventListener('click', function (ev) {
        ev.preventDefault();

          
        
          const destination = document.getElementById((this.hash).substring(1));
         

        if (!destination) return false;
        initiateMovement(destination);

      });
    }
  };

  const initiateMovement = (destination) => {
    const header = document.getElementsByTagName('header')[0];
    const subnav = document.getElementsByClassName('subnav')[0];

    // Get distance of item to scroll to from the top
    const destinationDistance = getOffset(destination).top;


    // Adjust distance based on height of header and, if present, subnav
    let adjustDistance = header.offsetHeight;
    if (subnav) {
      adjustDistance = header.offsetHeight + subnav.offsetHeight;
    } else {
      adjustDistance = header.offsetHeight;
    }

    let totalDistance = destinationDistance - adjustDistance + 10; // to scroll past anchor position
    scrollTo(totalDistance, 500);
  };


  function getOffset(element) {
    var rect = element.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }


  function scrollTo(element, duration) {
      var e = document.documentElement;
      
    if (e.scrollTop === 0) {
      var t = e.scrollTop;
      ++e.scrollTop;
      e = t + 1 === e.scrollTop-- ? e : document.body;
    }
    scrollToC(e, e.scrollTop, element, duration);
  }

  function scrollToC(element, from, to, duration) {
    if (duration <= 0) return;
    if (typeof from === 'object') from = from.offsetTop;
    if (typeof to === 'object') to  = to.offsetTop;

    scrollToX(element, from, to, 0, 1 / duration, 20, easeOutCuaic);
  }

  function scrollToX(element, xFrom, xTo, t01, speed, step, motion) {
    if (t01 < 0 || t01 > 1 || speed <= 0) {
      element.scrollTop = xTo;
      return;
    }
    element.scrollTop = xFrom - (xFrom - xTo) * motion(t01);
    t01 += speed * step;

    setTimeout(function () {
      scrollToX(element, xFrom, xTo, t01, speed, step, motion);
    }, step);
  }

  function easeOutCuaic(t) {
    t--;
    return t * t * t + 1;
  }


  window.addEventListener('scroll', function () {

    if (!ticking) {
      window.requestAnimationFrame(function () {

        ticking = false;
      });

      ticking = true;
    }
  });

    function handleAnchorOnload() {
        if (!window.location.hash) return false;
        setTimeout(function () {
            // Get the hash from the URL and convert it to lowercase
            var hash = window.location.hash.toLowerCase();

            // Remove the '#' from the hash
            var targetId = hash.substring(1);

            // Convert the NodeList to an array and find the element by matching lowercase IDs
            const destination = Array.from(document.querySelectorAll('[id]')).find(
                (el) => el.id.toLowerCase() === targetId
            );

            // If the destination element is not found, exit the function
            if (!destination) return false;

            // If the destination element is found, initiate movement
            initiateMovement(destination);
        }, 1000);
    }


  function init() {
    handleAnchorOnload();
    scrollToContent();
  }

  return {
    init: init
  };
}());

export default ScrollTo;